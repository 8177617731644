<template>
  <div
    class="tw-container"
    v-if="
      (groupResult && groupResult.name && ((groupResult.percent > 0 && groupResult.percent < 100) || groupResult.error.length > 0)) ||
      (pageResult && pageResult.name && ((pageResult.percent > 0 && pageResult.percent < 100) || pageResult.error.length > 0))
    "
  >
    <p class="fw-bolder text-size18 mb-3">目前正在背景處理的項目...</p>
    <hr>
    <ul class="fw-bolder list">
      <!-- FB社團留言 -->
      <li>
        <p class="row">
          <span class="col-4 p-0">同步<span class="text-primary">FB社團</span>文章留言: </span>
          <div class="col-8 p-0">
            <span v-if="groupResult.linkId">社團ID: {{ groupResult.linkId }}</span>
            <p v-if="groupResult === null">請稍後...</p>
            <template v-else>
              <template v-if="groupResult.name && groupResult.percent > 0 && groupResult.error.length === 0">
                <ProgressBar
                  v-if="groupResult.percent < 100"
                  :progressData="{
                    target: 'backgroundHandle',
                    percent: groupResult.percent,
                  }"
                ></ProgressBar>
                <p class="text-success" v-else>已完成</p>
              </template>
              <p class="text-danger" v-else-if="groupResult.name && groupResult.error.length > 0">
                有部份商品已無法從 FB 同步留言 ! <span class="text-primary pointer" @click="showModal('checkError', 'group')">點我查看那些商品</span>
              </p>
              <p class="text-warning" v-else-if="groupResult.name === '尚未綁定社團'">您尚未在此賣場綁定 FB 社團</p>
            </template>
          </div>
        </p>
      </li>
      <!-- FB粉專留言 -->
      <li>
        <p class="row">
          <span class="col-4 p-0">同步<span class="text-danger">FB粉專</span>文章留言: </span>
          <div class="col-8 p-0">
            <span v-if="groupResult.linkId">粉專ID: {{ pageResult.linkId }}</span>
            <p v-if="pageResult === null">請稍後...</p>
            <template v-else>
              <template v-if="pageResult.name && pageResult.percent > 0">
                <ProgressBar
                  v-if="pageResult.percent < 100"
                  :progressData="{
                    target: 'backgroundHandle',
                    percent: pageResult.percent,
                  }"
                ></ProgressBar>
                <p class="text-success" v-else>已完成</p>
              </template>
              <p class="text-danger" v-else-if="pageResult.name && pageResult.error.length > 0">
                有部份商品已無法從 FB 同步留言 ! <span class="text-primary pointer" @click="showModal('checkError', 'page')">點我查看那些商品</span>
              </p>
              <p class="text-warning" v-else-if="pageResult.name === '尚未綁定粉專'">您尚未在此賣場綁定 FB 粉專</p>
            </template>
          </div>
        </p>
      </li>
    </ul>
  </div>
  <!-- 查看無法同步的商品的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkErrorModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">無法同步之商品</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-danger fw-bolder p-3">以下商品皆無法從 FB 取回留言</p>
          <ul class="list ps-4" v-if="whitchOne === 'group' && groupResult && groupResult.name && ((groupResult.percent > 0 && groupResult.percent < 100) || groupResult.error.length > 0)" style="list-style-type: decimal">
            <li v-for="data in groupResult.error" :key="data.feedLink.id">
              {{ data.merchandise.name }}
            </li>
          </ul>
          <ul class="list ps-4" v-else-if="whitchOne === 'page' && pageResult && pageResult.name && ((pageResult.percent > 0 && pageResult.percent < 100) || pageResult.error.length > 0)" style="list-style-type: decimal">
            <li v-for="data in pageResult.error" :key="data.feedLink.id">
              {{ data.merchandise.name }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from 'vuex'
// components
import ProgressBar from '@/components/primeVue/ProgressBar.vue'

export default {
  /**
   * 檢查同步 FB 社團/粉專留言會放在 app.vue 內觸發，只有在 currentModal = seller 才會觸發
   */
  components: { ProgressBar },
  computed: {
    ...mapState('SyncFbMessages', {
      groupResult: state => state.groupResult,
      pageResult: state => state.pageResult,
    }),
  },
  data() {
    return {
      // modal
      checkErrorModal: {},
      // data
      whitchOne: 'group'
    }
  },
  mounted() {
    this.createModals(['checkErrorModal'])
  },
  methods: {
    showModal(status, item) {
      if (status === 'checkError') {
        this.whitchOne = item
        this.checkErrorModal.show()
      }
    },
  },
}
</script>